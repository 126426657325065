import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const headerCarousels = document.querySelectorAll('.block-carrousel-header');

  if (0 < headerCarousels.length) {
    headerCarousels.forEach(function (headerCarousel) {
      const swiperConfig = {
        draggable: false,
        loop: true,
        slidesPerView: 1,
        effect: 'fade',
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
        navigation: {
          enable: false,
        },
      };

      new Swiper(headerCarousel.querySelector('.header-swiper'), swiperConfig);
    });
  }
});
